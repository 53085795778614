@import "src/app/online/account/account.component";
@import "adminBook";
@import "adminCode";
@import "adminLicenseBook";
@import "adminMagazine";
@import "adminMagazineSubscription";
@import "adminPeriodic";
@import "adminStat";
@import "adminUser";
@import "adminView";
@import "advanced-search";
@import "book";
@import "bookLicenseCompany";
@import "calculator";
@import "src/app/components/pdf-reader/pdf-reader.component";
@import "code";
@import "company";
@import "cookies";
@import "document";
@import "document-submenuBar";
@import "filter";
@import "folder";
@import "footer";
@import "header";
@import "home";
@import "information";
@import "images";
@import "license";
@import "my-searches";
@import "pagination";
@import "spinner";

html, body {
  min-height: 100% !important;
  height: 100%;
}

body {
  color: $blue;
  font-family: $font-family;
  font-weight: $fw-regular;
  font-size: $fs-smaller;
  overflow: hidden;
  background-color: $gray-bg;
  -webkit-font-smoothing: antialiased;
}

#btn-close-menu {
  margin-right: 20px;
}

.margin-top20 {
  margin-top: 60px;
}

.margin-right-label {
  margin-right: 7px;
}

.clear {
  clear: both;
}

.floatingBtn {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

@media (max-width: 749px), (min-width: 768px) and (max-width: 967px) {

  .floatingBtn {
    opacity: 1;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 591;
  }
}

.badge-mr {
  margin-right: 6px;
}

.market-badge {
  color: white;
  background-color: #D3AF7B !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  display: block;
}

a {
  color: $text-color;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

a:hover, a:focus {
  text-decoration: none;
  cursor: pointer;
  color: $text-color;
}

p {
  color: $blue;
}

svg {
  width: 32px;
  height: 32px;
}

.tag {
  background-color: $gray-lighter;
  display: inline-block;
  font-size: $fs-small;
  font-weight: $fw-medium;
  line-height: 1;
  padding: 6px 12px 8px;
  color: $blue;
  border-radius: 20px;
  border: 1px solid $gray-lighter;

  &.red {
    background-color: #fae8e4;
    color: $red;
  }

  &.primary {
    background-color: $blue-darkest;
    color: $gray-lighter;
  }

  &.reverse-primary {
    background-color: $white;
    color: $blue-darkest;
    border: 1px solid $gray-lighter;
  }
}

.clickable {
  cursor: pointer;
  transition: color $trans;
}

.clickable:hover {
  color: $blue-dark;
}

.table .clickable {
  color: $blue;

  &:hover {
    color: $blue-dark;
  }
}

.faWebfont {
  font-size: 10px;
  font-weight: $fw-bold;
  color: $gold;
  vertical-align: top;
}

.text-gold {
  color: $gold;
}

.fs-7 {
  font-size: 0.9rem;
}

.bg-gold {
  background-color: $gold;
}
.bg-gray {
  background-color: $gray-bg;
}

.errorMessage {
  color: $red;
  font-size: $extra-small-text;
}

.warningMessage {
  color: $red;
  font-style: italic;
  font-size: $extra-small-text;
}

.height20 {
  height: 20px;
}

.height40 {
  height: 40px;
}

.height50 {
  height: 50px;
}

.bottom5 {
  bottom: 5px;
}

.bottom10 {
  bottom: 10px;
}

.padright0 {
  padding-right: 0;
}

.margin-right10 {
  margin-right: 10px;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $white;
  box-shadow: 0 4px 10px $gray-shadow-big;
  cursor: pointer;
  text-indent: -9999px;
  opacity: 0;
  transition: opacity $trans, box-shadow $trans;
  padding: 7px;

  svg {
    display: block;
    width: 26px;
    height: 26px;

    * {
      transition: stroke $trans;
      stroke: $gold;
    }
  }

  &:hover {
    box-shadow: 0 12px 20px $gray-shadow-big;

    svg * {
      stroke: $gold-dark;
    }
  }
}

.visible {
  opacity: 1;
}

.w-600 {
  width: 600px;
}

.max-width-500 {
  max-width: 500px;
}

.max-width-800 {
  max-width: 800px !important;
  width: 100%;
}
.max-width-80-percent {
  max-width: 80%;
  width: 100%;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.max-wrapper-width-md {
  max-width: 800px;
}

.field-form-container {
  .field-form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .field-label {
      width: 35%;
      label {
        font-weight: 300;
      }
    }
    .field-value {
      width: 65%;
      text-align: left;
      font-weight: normal;
    }
  }

  .form-field-errors, .form-field-hint {
    margin-left: 35%;
    display: block;
  }
}

.ms-not-reveal::-ms-reveal, .ms-not-reveal::-ms-clear {
  display: none;
}

.grecaptcha-badge {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.stylized-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.black-text {
  color: $black;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  &.line-clamp-1 {
    -webkit-line-clamp: 1;
  }
  &.line-clamp-2 {
    -webkit-line-clamp: 2;
  }
  &.line-clamp-3 {
    -webkit-line-clamp: 2;
  }
}

/************ override bootstrap *****************/

.form-horizontal .control-label {
  text-align: left;
}

.radio-inline, .checkbox-inline {
  margin-right: 10px;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
  margin: 0;
}

/******************* TABLE *****************/
.cell-align-right {
  text-align: right;
}

.lighter-text {
  font-weight: lighter;
  color: lightgray;
}

.selecter-translator {
  width: 50% !important;
  display: inline !important;
}

.button-translator {
  display: inline;
}

.spinner-translator {
  background-color: rgba(255, 255, 255, 0.5);

  .loading {
    margin-left: 20px;
    width: 35px;
    height: 35px;
    background-color: $white;

    svg {
      height: 32px;
      animation: rotatePlane 1.2s infinite ease-in-out;
    }
  }
}

@keyframes rotatePlane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}


/************ CONTAINERS *****************/

.container-fluid {
  height: 100%;
  padding: 0 !important;
}

.container-alert {
  position: fixed;
  height: auto;
  left: 0;
  width: 100%;
  z-index: 5000;
  text-align: center;

  /* Alert */
  .alert {
    padding: 20px 32px;
    margin: 30px auto 0;
    background-color: #38A279;
    border-radius: 30px;
    display: inline-block;
    border: none;
    max-width: 90%;

    &.alert-danger, &.alert-warning {
      background-color: #DD3F19;
    }

    p {
      color: $white;
      font-size: $fs-big;
      font-weight: $fw-medium;
      line-height: 1;
      padding-right: 30px;
    }

    .close {
      color: $white;
      opacity: 1;
      font-family: Tahoma, sans-serif;
      transition: all $trans;
      font-weight: 300;
      font-size: 16px;
      float: right;
    }
  }

  &.alert-offline {
    padding-left: 0;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    .alert {
      p {
        white-space: normal;
        font-size: $fs-small;
      }
    }
  }
}

.container-content:not(:has(.library-results-container)) {
  overflow-y: auto;
}

.container-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  top: $header-menu-height;
  overflow: hidden;
  overflow-y: auto;

  &.offline-content {
    left: 0;
    padding-top: 4%;
    padding-bottom: 4%;

    .home {
      h1 {
        font-size: 30px;
        font-weight: $fw-bold;
        color: $blue-darker;
        margin: 65px auto 40px;
      }

      .forget-password {
        font-size: $fs-smallest;
        text-align: right;
        margin-bottom: 30px;
      }

      .no-account {
        margin-top: 150px;
        line-height: 1.5;
        @media (max-width: $screen-xs-max) {
          margin-top: 20px;
        }
      }

      .errorMessage {
        margin-right: 0;
        font-size: $fs-smallest;
        line-height: 20px;

        &.text-center {
          margin-bottom: 30px;
        }
      }


    }
  }

  .disconnect-button {
    position: fixed;
    right: 10px;
    top: 25px;
    z-index: 600;
    @media (max-width: $screen-sm-max) {
      padding: 25px 25px 0 0;
    }
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .disconnect-button .btn {
    background-color: white;
    color: #D3AF7B;
  }

  #content {
    .container {
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: 100% !important;
    }

    #mainContent {
      padding: 25px 4vw;
    }
  }

  #content > div {
    min-height: 100%;
  }

  @media (max-width: $screen-xs-max) {
    left: 0;
    top: $header-menu-height;
    &.offline-content {
      top: 0;
      padding-bottom: 30px;
    }
  }
}

.container-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $small-footer-height;
  line-height: $small-footer-height;
  background-color: $footer-gray;
}

.panel-button-footer {
  position: fixed;
  background: $white;
  border-top: 1px solid $gray-lighter;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px 58px 0 58px;
  z-index: 1;

  .btn {
    margin-bottom: 20px;
    margin-left: 20px;

    &:last-child {
      margin-left: 0;
    }
  }

  @media (max-width: 1279px) {
    padding: 20px 30px 0 30px;
  }
  @media (max-width: 767px) {
    left: 0;
  }
}

.panel-note {
  width: 100%;
  position: absolute;
  bottom: 10px;
}

.section-title {
  font-size: $fs-smallest;
  color: $gold;
  font-weight: $fw-bold;
  text-transform: uppercase;
  margin: 0;
  padding: 15px 0;
  @media(min-width: 430px) {
    padding-right: 160px;
  }
  @media (max-width: 830px) {
    margin-bottom: 100%;
  }
}

.panel-documents {
  margin-bottom: 70px;
  @media(min-width: 430px) {
    padding-right: 160px;
  }
  @media (max-width: 830px) {
    margin-bottom: 100%;
  }
}


/************ SCREEN SIZE  *****************/

@include medium-screen() {

  .scroll-to-top {
    bottom: 20px;
  }

  .container-content {
    #mainContent {
      .documentDetail {
        .documentDetailNav > ul {
          font-size: $small-text;
        }
      }

      .calculator {
        .indexing {
          .rightPanel {
            height: 480px;

            .scrolledTable {
              overflow: scroll;
              height: 350px;
            }
          }
        }

        .interest {
          .calculator-content {
            .rightPanel {
              .bottomButton {
                bottom: 45px;
              }

              .postTypes {
                #legal-majorated {
                  .majoratedBy, .points {
                    font-size: 0.8rem;
                  }
                }
              }
            }
          }

          .calculator-content .sub-panel {
            height: 450px;
            position: relative;

            .calculatorCenterBtn {
              position: absolute;
            }
          }

          font-size: $extra-small-text;
        }

        .sub-panel .form-group:not(.heading) {
          font-size: $extra-small-text;
        }
      }

      .adminCalculator {
        .calculatorsTable {
          tbody {
            height: 400px;
          }
        }
      }

      .submenu .header {
        .left-element {
          width: 30%;
        }

        .middle-element {
          width: 35%;
        }

        .right-element {
          width: 34%;
        }
      }
    }
  }

  .container-alert {
    height: auto;
  }

  .container-footer {
    height: $large-footer-height;
    line-height: $large-footer-height;
  }
}

@include large-screen() {
  html {
    font-size: 16px;
  }

  .container-header {
    height: $large-login-height;
  }

  .container-menu {
    margin-bottom: $large-footer-height;

    .content-headermenu {
      .main-title {
        .lexnow-icon-title a {
          font-size: 1.5rem;
        }
      }
    }
  }

  .container-footer {
    height: $large-footer-height;
    line-height: $large-footer-height;
  }

  .container-content {
    #mainContent {
      .documentDetail {
        .documentDetailNav {
          .onlineBtn {
            height: 45px;
          }
        }

        .documentDetailNav > ul {
          font-size: $normal-text;
        }

        .documentDetailNav > ul > li {
          width: 17%;
          height: 45px;
        }
      }

      .adminCalculator {
        .calculatorsTable {
          tbody {
            height: 500px;
          }
        }
      }

      .calculator {
        .sub-panel .form-group:not(.heading) {
          font-size: $smaller-text;
        }
      }

      .submenu .header {
        .left-element {
          width: 31%;
        }

        .middle-element {
          width: 34%;

          .pagination {
            .marginPagination {
              margin: 2px 0;
            }
          }
        }

        .right-element {
          width: 34%;
        }
      }
    }
  }
}

@include larger-screen() {
  html {
    font-size: 17px;
  }

  .container-menu {
    .content-headermenu {
      .main-title {
        .lexnow-icon-title a {
          font-size: 1.9rem;
        }
      }
    }
  }

  .container-content {
    #mainContent {

      .documentDetail {
        .documentDetailNav {
          .onlineBtn {
            height: 55px;
          }
        }

        .documentDetailNav > ul {
          font-size: $larger-text;
        }

        .documentDetailNav > ul > li {
          width: 17%;
          height: 55px;
        }
      }

      .adminCalculator {
        .calculatorsTable {
          tbody {
            height: 800px;
          }
        }
      }

      .submenu {
        .header {
          height: 50px;
          line-height: 50px;
          width: calc(100% - 60px);

          .left-element {
            width: 33%;
          }

          .middle-element {
            width: 33%;

            .navigation .pagination {
              .marginPagination {
                margin: 4px 0;
              }

              .page-item > span {
                padding: 0 10px;
                height: 50px;
                line-height: 50px;
              }
            }

            .page-link {
              padding: 0 10px;
              height: 50px;
              line-height: 50px;
            }
          }

          .right-element {
            width: 33%;
          }
        }

        .resultMenu {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

/************ TABLES  *****************/
.res-table-container {
  overflow-x: unset;

  table {
    width: 100%;
    border-spacing: 0;
    background-color: transparent;
    font-size: $fs-small;
    font-weight: $fw-regular;
    text-align: left;

    tr {
      padding: 0 58px;
    }

    th, td {
      &:first-child {
        padding-left: 58px;
      }

      &:last-child {
        padding-right: 58px;
      }
    }

    thead {
      tr {
        th {
          padding-top: 20px;
          padding-bottom: 20px;
          color: $gold;
          font-weight: $fw-medium;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      tr {
        background-color: white;
        transition: background-color $trans;
        border-bottom: 1px solid $gray-lighter;

        &:hover {
          background-color: #f3f3f3;
        }

        &:last-child {
          border-bottom: none;
        }

        td {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    table {
      th, td {
        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }
    }
  }
}
